import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  PageContextDefinition,  
  FocusElementProvider, useEmbedComponents, 
  EmbedComponentsProvider, AppStateProvider,
  UiConfig,
  UiProvider,
  LayoutShell,
  SiteFooterLayout,
  DataHook,
  usePages,
} from '@shapeable/ui';

import { VERY_LIGHT_BROWN, DARK_GREEN, GREEN, LIGHT_BROWN, GOLD } from '../theme';
import * as pageLayouts from './page-layouts';
import * as sliceLayouts from './slice-layouts';
import { LANG_STRINGS } from '../lang';
import { PostsProvider } from '../gatsby/providers/posts-provider';
import { MenusProvider } from '../gatsby/providers/menus-provider';
import { OrganisationPartnersProvider } from "../gatsby/providers/organisation-partners-provider";
import { StrategicIntelligenceWidget } from "./elements/strategic-intelligence-widget";
import { LayoutStyles, themeOverrides } from '../theme';
import { defaultAppState } from '../hooks/use-app-state';
import { ReflectionsProvider } from '../gatsby/providers/reflections-provider';

export type LayoutPropTypes = Classable & HasChildren & {
  path?: string;
  data?: any;
  pageContext?: PageContextDefinition;
};

const LayoutDefaultProps: LayoutPropTypes = {
};

// -------- Styles --------> 

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles}
  `,
});

const FooterStyles = breakpoints({
  base: css`
    flex-grow: 0;
    flex-shrink: 0;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
});


// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
  Body: styled.div`${BodyStyles}`,
  Footer: styled(SiteFooterLayout)`${FooterStyles}`,

};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children } = props;

  const embedComponents = useEmbedComponents();

  const ref = React.useRef();
  const focusRef = React.useRef();

  const config: UiConfig = {
    layouts: { pageLayouts, sliceLayouts, defaultPageLayout: pageLayouts.PageLayoutDefault },
    langStrings: LANG_STRINGS,
    theme: themeOverrides,
    header: {
      barHeight: 3,
      activeColor: LIGHT_BROWN,
      hoverColor: LIGHT_BROWN,
    },
    search: {
      isAvailable: true,
      extraDataHooks: {
        Page: usePages as DataHook,
      },
    },
    menuBar: {
      backgroundColor: GREEN,
    },
    footer: {
      buttonHoverColor: LIGHT_BROWN,
    },
    entity: {
      dataProviders: [
        MenusProvider, OrganisationPartnersProvider,
        PostsProvider, ReflectionsProvider,
      ]
    }
  }

  return (
    <AppStateProvider value={defaultAppState}>
    <UiProvider value={config}>
    <FocusElementProvider value={focusRef}>
    <EmbedComponentsProvider value={{
      ...embedComponents,
      StrategicIntelligence: StrategicIntelligenceWidget
    }}>
    {/* <SiteHeaderProvider value={{ component: SiteHeaderLayout }}> */}
      <My.Container
        id="#container"
        ref={ref}
        hideOverflow={false}
        className={className}
        includeTooltip
        modalColor={DARK_GREEN}
        tooltipProps={{
          backgroundColor: VERY_LIGHT_BROWN,
          globalEventOff: 'click',
        }}
      >
        <My.Body>
          {children}
        </My.Body>
        <My.Footer backgroundColor={VERY_LIGHT_BROWN} />
      </My.Container>
    {/* </SiteHeaderProvider> */}
    </EmbedComponentsProvider>
    </FocusElementProvider>
    </UiProvider>
    </AppStateProvider>
  );
  
  
};

Layout.defaultProps = LayoutDefaultProps;

